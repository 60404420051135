<template>
  <div class="flex flex-col min-h-screen overflow-hidden">

    <!-- Site header -->
    <Header />

    <!-- Page content -->
    <main class="flex-grow">
      <!-- Page sections -->
      <CenikIntro />
      <CenikInfo />    
    </main>

    <!-- Site footer -->
    <Footer />

  </div>
</template>

<script>
import Header from './../partials/Header.vue'
import CenikIntro from './../partials/CenikIntro.vue'
import CenikInfo from './../partials/CenikInfo.vue'
import Footer from './../partials/Footer.vue'

export default {
  name: 'Cenik',
  title: 'Árlista',
  components: {
    Header,
    CenikIntro,
    CenikInfo,
    Footer,
  },
}
</script>
