<template>
  <section>
    <div class="max-w-6xl mx-auto px-4 sm:px-6">
      <div class="pt-32 md:pt-40">

        <!-- Section header -->
        <div class="max-w-6xl mx-auto text-left pb-12 md:pb-16">
          <h1 class="h1 mb-4">Árlista</h1>
          <p class="text-xl text-gray-600">
            A licencek megvásárlására számos és rugalmas lehetőséget kínálunk. Az egyfelhasználós licencektől kezdve az egy adott vállalatnak szóló komplex teljeskörű megoldásokig vagy egy adott projekthez szükséges számú licencek biztosításáig.
          </p>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutIntro',
}
</script>